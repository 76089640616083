import React, {useContext, useState} from "react";
import {Button, Card, InputNumber, Popconfirm} from "antd";
import {BlockOutlined} from "@ant-design/icons";
import {DataContext} from "../../store/DataContext";
const Page = () => {
    
    const { options, players, darkMode } = useContext(DataContext);
    const [tempOptions, setTempOptions] = useState(options)
    const [tempPlayers, setTempPlayers] = useState(players.flatMap(item => Array(options.length / players.length).fill(item)))
    
    const [showDice, setShowDice] = useState(false)
    const [isFinish, setIsFinish] = useState(false)
    const [dice, setCount] = useState([1,1])
    const [intervalId, setIntervalId] = useState(0)
    
    
    return (
        <div className="page-container">
            <Card title="随机" extra={
                <Popconfirm
                    open={showDice}
                    placement="bottom"
                    icon={<BlockOutlined style={{color: darkMode ? "#fff" : "#000"}}/>}
                    title="摇号"
                    showCancel={false}
                    okButtonProps={{style: {display: "none"}}}
                    description={
                        <div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div>数量：</div>
                                <div>
                                    <InputNumber value={dice.length} onChange={(e) => setCount(new Array(e).fill(1))}/>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: 20, alignItems: "center", padding: 20}}>
                                {
                                    dice.map(item => (
                                        <div className={`dice dice-${item}`} style={{backgroundColor: darkMode ? "#f5f5f5" : "#f5f5f5", border: "1px solid #ccc"}}>
                                            {[...Array(item)].map((_, i) => (
                                                <div key={i} className="dot"/>
                                            ))}
                                        </div>
                                    ))
                                }
                            </div>
                            <div style={{display: "flex", gap: 10, flexDirection: "column", alignItems: "center"}}>
                                <Button block type="primary" onClick={() => {
                                    if (intervalId) {
                                        clearInterval(intervalId)
                                        setIntervalId(0)
                                    } else {
                                        let id = setInterval(() => {
                                            setCount(Array(dice.length).fill(0).map(() => Math.floor(Math.random() * 6) + 1))
                                        }, 10)
                                        setIntervalId(id)
                                    }
                                }}>{intervalId ? "停止" : "随机"}</Button>
                                <Button onClick={() => setShowDice(false)}>关闭</Button>
                            </div>
                        </div>
                    }
                >
                    <Button onClick={() => setShowDice(true)}>摇号</Button>
                </Popconfirm>
            }>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {tempOptions.map((item, index) => (
                        <div style={{display: "flex", gap: 20, alignItems: "center", width: "100%"}}>
                            <div style={{flex: 1, textAlign: "right"}}>{item}</div>
                            <div style={{borderTop: "1px solid #888", width: 50}}></div>
                            <div style={{flex: 1}}>
                                {/*{*/}
                                {/*    (index !== 20) ?*/}
                                {/*        playerTempList[index]*/}
                                {/*    :*/}
                                {/*    <Input style={{width: 200}}></Input>*/}
                                {/*}*/}
                                
                                {tempPlayers[index]}
                            </div>
                        </div>
                    ))}
                    <div style={{display: isFinish ? "none" : "flex", paddingTop: 20}}>
                        <Button onClick={() => {
                            let array = [...tempOptions]
                            for (let i = array.length - 1; i > 0; i--) {
                                const j = Math.floor(Math.random() * (i + 1));
                                [array[i], array[j]] = [array[j], array[i]]; // 交换元素
                            }
                            setTempOptions(array)
                        }}>选项随机</Button>
                        <div style={{width: 90}}></div>
                        <Button onClick={() => {
                            let array = [...tempPlayers]
                            for (let i = array.length - 1; i > 0; i--) {
                                const j = Math.floor(Math.random() * (i + 1));
                                [array[i], array[j]] = [array[j], array[i]]; // 交换元素
                            }
                            setTempPlayers(array)
                        }}>玩家随机</Button>
                    </div>
                </div>
            </Card>
            <Card title="最终结果" extra={<Button onClick={() => setIsFinish(false)} type={isFinish ? "primary" : "default"}>重新随机</Button>}>
                {
                    isFinish ? Array.from(new Set(players)).map((player, index) => (
                    <div style={{display: "flex", gap: 20, alignItems: "center", width: "100%", paddingBottom: 20}}>
                        <div style={{textAlign: "right", width: 200}}>
                            {player === "" ? "轮空" : (index + 1) + ". " + player}
                        </div>
                        <div style={{borderTop: "1px solid #888", width: 50}}></div>
                        <div style={{flex: 1, display: "flex", flexWrap: "wrap", gap: "5px 20px"}}>
                            {tempOptions.map((option, oIndex) => {
                                if (tempPlayers[oIndex] === player) {
                                    return <text>{option}</text>
                                }
                                return null
                            })}
                        </div>
                    </div>
                )) : <div style={{display: "flex", justifyContent: "center"}}><Button type="primary" onClick={() => setIsFinish(true)}>确认结果</Button></div>}
            </Card>
        </div>
    );
}

export default Page;
