const Content = {
    title: "Card Web",
    company: "2021-2023 ULink Co.",
    icp: "沪ICP2023002085号",
    logoUrl: "https://gw.alipayobjects.com/zos/antfincdn/FLrTNDvlna/antv.png",

    primaryColor: "#597ef7",

}

export default Content;
