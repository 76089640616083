import {useEffect, useState} from "react";
import './index.css'
import PlayerGraph from "./index";
import {playerDetailQuery} from "../../config/api";
import {DownOutlined, FallOutlined, QuestionCircleOutlined, RiseOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {Modal, Tooltip} from "antd";

const PlayerDetail = () => {
    const navigate = useNavigate()
    const [data, setData] = useState({
        playerDetail: {
            name: "",
            teamName: "",
            peopleImage: "",
            teamImage: "",
            number: "",
            position: "",
            height: "",
            weight: "",
            birthday: "",
            draft: "",
        },
        newsList: []
    })
    const [player, setPlayer] = useState()

   useEffect(() => {
       
       let queryMessage = location.search.slice(1).replaceAll("%20", " ")
       if (queryMessage === "") {
           queryMessage = "Luka Doncic"
       }
       setPlayer(queryMessage)
       document.title = queryMessage
       playerDetailQuery({query: queryMessage, days: 7}).then(r => {
           setData(r)
       })
    }, [])
    return <div style={{backgroundColor: "#141414", minHeight: "100vh"}}>
        <div style={{display: "flex", padding: "25px 0", color: "#fff", backgroundColor: "#1f2851",height: 90,}}>
            <div style={{width: "30vw", maxHeight: "40vw", overflow: "hidden", position: "relative", padding: "0 20px"}}>
                <img style={{position: "absolute", width: "30vw", opacity: 0.5}}
                     src={data?.playerDetail?.teamImage} alt=""/>
                <img style={{position: "absolute", width: "30vw", bottom: 0}}
                     src={data?.playerDetail?.peopleImage} alt=""/>
            </div>
            <div style={{flex: 1, fontSize: 10, marginTop: 15}}>
                <div style={{fontSize: 20, fontWeight: 600}}>{data?.name}</div>
                <div style={{display: "flex", gap: 10, alignItems: "center", marginBoWttom: 2}}>
                    <img style={{width: 20, opacity: 0.5, marginRight: -10}} src={data?.playerDetail?.teamImage} alt=""/>
                    <div>{data?.playerDetail?.teamName}</div>
                    <div>·</div>
                    <div>{data?.playerDetail?.number}</div>
                    <div>·</div>
                    <div>{data?.playerDetail?.position}</div>
                </div>
                <div style={{display: "flex"}}>
                    <div style={{width: 90}}>身高/体重</div>
                    <div>{data?.playerDetail?.height}, {data?.playerDetail?.weight}</div>
                </div>
                <div style={{display: "flex"}}>
                    <div style={{width: 90}}>出生日期</div>
                    <div>{data?.playerDetail?.birthday}</div>
                </div>
                <div style={{display: "flex"}}>
                    <div style={{width: 90}}>选秀信息</div>
                    <div>{data?.playerDetail?.draft}</div>
                </div>
            </div>
        </div>
        <div style={{width: "100vw", height: 20, background: "-webkit-linear-gradient(top, #1f2852, #141414)"}}>
        
        </div>
        
        <div style={{margin: "2px 0", padding: "10px 20px 0", display: "flex", flexDirection: "column", gap: 20, color: "#fff"}}>
            <div className="cardaaa" style={{
                borderRadius: 20,
                padding: 20,
                display: "flex",
                flexWrap: "wrap",
                 fontSize: 12
            }}>
                <div style={{flex: 1, minWidth: "30%", textAlign: "center"}}>
                    <div>成交总量</div>
                    <div style={{color: "#ffa400"}}>{data?.playerDetail?.totalSaleCount}</div>
                </div>
                <div style={{flex: 1, minWidth: "30%", textAlign: "center"}}>
                    <div style={{marginTop: 20}}>CPI  <Tooltip placement="top" title={<div>CPI代表CardCool Player Index</div>}>
                        <QuestionCircleOutlined style={{fontSize: 12}}/>
                    </Tooltip></div>
                    <div style={{color: "#f6de57", fontSize: 18, fontWeight: 900, marginTop: -5}}>{data?.index}</div>
                </div>
                <div style={{flex: 1, minWidth: "30%", textAlign: "center"}}>
                    <div>成交总金额</div>
                    <div style={{color: "#ffa400"}}>{data?.playerDetail?.totalSaleAmount?.toFixed(2)}</div>
                </div>
                <div style={{flex: 1, minWidth: "30%", textAlign: "center", marginTop: -10}}>
                    <div>历史最高单价</div>
                    <div style={{color: "#ffa400"}}>{data?.playerDetail?.maxAmount?.toFixed(2)}</div>
                </div>
                <div style={{flex: 1}}>
                </div>
                <div style={{flex: 1, minWidth: "30%", textAlign: "center", marginTop: -10}}>
                    <div>趋势变化</div>
                    <div style={{color: data?.indexChange > 0 ? "#f00" : "#0f0"}}>
                        {data?.indexChange}% {data?.indexChange > 0 ? <RiseOutlined/> : <FallOutlined />}</div>
                </div>
            </div>
        </div>
        
        <div style={{padding: "10px 20px"}}>
            <PlayerGraph/>
        </div>
        <div style={{padding: "0 20px 100px", display: "flex", flexDirection: "column", gap: 20}}>
            {
                data?.newsList?.length > 0  && <div style={{color: "white"}}>球员相关新闻</div>
            }
            {
                data?.newsList?.map(item => {
                    return <>
                        <div style={{backgroundColor: "#bbb", borderRadius: 20}} onClick={() => navigate(`/page/newsDetail/${player}/${item.id}`)}>
                            <img src={item.images[0]} style={{width: "100%", borderRadius: "20px 20px 0 0"}}
                                 alt=""></img>
                            <div style={{padding: "10px 20px"}}>
                                <div style={{fontSize: 16}}>{item.title}</div>
                                <div style={{display: "flex", alignItems: "center", padding: "10px 0 0 0"}}>
                                    <div style={{flex: 1, display: "flex", gap: 10, alignItems: "center"}}>
                                        <div style={{fontSize: 12}}>{dayjs(item.createDate).format('YYYY-M-DD')}</div>
                                        <div style={{
                                            backgroundColor: "#bdf",
                                            borderRadius: 5,
                                            padding: "5px 10px",
                                            fontSize: 10
                                        }}>{item.tags[0]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                })
            }
        </div>
    </div>
}
export default PlayerDetail