import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Input, Modal, Select} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {DataContext} from "../../store/DataContext";
import {useNavigate} from "react-router-dom";

const remoteData = [
    {
        key: "NBA - 队名",
        data: [
            "波士顿凯尔特人 (Boston Celtics)",
            "布鲁克林篮网 (Brooklyn Nets)",
            "纽约尼克斯 (New York Knicks)",
            "费城76人 (Philadelphia 76ers)",
            "多伦多猛龙 (Toronto Raptors)",
            "芝加哥公牛 (Chicago Bulls)",
            "克利夫兰骑士 (Cleveland Cavaliers)",
            "底特律活塞 (Detroit Pistons)",
            "印第安纳步行者 (Indiana Pacers)",
            "密尔沃基雄鹿 (Milwaukee Bucks)",
            "亚特兰大老鹰 (Atlanta Hawks)",
            "夏洛特黄蜂 (Charlotte Hornets)",
            "迈阿密热火 (Miami Heat)",
            "奥兰多魔术 (Orlando Magic)",
            "华盛顿奇才 (Washington Wizards)",
            "丹佛掘金 (Denver Nuggets)",
            "波特兰开拓者 (Portland Trail Blazers)",
            "犹他爵士 (Utah Jazz)",
            "金州勇士 (Golden State Warriors)",
            "洛杉矶快船 (Los Angeles Clippers)",
            "洛杉矶湖人 (Los Angeles Lakers)",
            "菲尼克斯太阳 (Phoenix Suns)",
            "萨克拉门托国王 (Sacramento Kings)",
            "达拉斯独行侠 (Dallas Mavericks)",
            "休斯顿火箭 (Houston Rockets)",
            "孟菲斯灰熊 (Memphis Grizzlies)",
            "新奥尔良鹈鹕 (New Orleans Pelicans)",
            "圣安东尼奥马刺 (San Antonio Spurs)",
            "明尼苏达森林狼 (Minnesota Timberwolves)",
            "俄克拉荷马城雷霆 (Oklahoma City Thunder)",
        ]
    },
    {
        key: "宝可梦 - 属性",
        data: [
            "草系 (Grass)",
            "火系 (Fire)",
            "水系 (Water)",
            "电系 (Electric)",
            "虫系 (Bug)",
            "普通系 (Normal)",
            "毒系 (Poison)",
            "飞行系 (Flying)",
            "地面系 (Ground)",
            "超能力系 (Psychic)",
            "岩石系 (Rock)",
            "冰系 (Ice)",
            "龙系 (Dragon)",
            "幽灵系 (Ghost)",
            "格斗系 (Fighting)",
            "恶系 (Dark)",
            "钢系 (Steel)",
            "妖精系 (Fairy)"
        ]
    }
]


const Page = () => {
    
    const navigate = useNavigate();
    
    const [allOptions, setAllOptions] = useState([])
    const { options, setOptions, players, setPlayers, darkMode } = useContext(DataContext);
    const [tempData, setTempData] = useState("")
    const [tempPlayers, setTempPlayers] = useState("")
    const [selectOption, setSelectOption] = useState(0)
    const [editOption, setEditOption] = useState(false)
    const [playerDivisors, setPlayerDivisors] = useState([])
    const [playerCount, setPlayerCount] = useState(0)
    const [editPlayer, setEditPlayer] = useState(false)
    
    useEffect(() => {
        setAllOptions(remoteData)
        if (options.length === 0) {
            doSetData(remoteData[0].data)
        } else {
            doSetData(options)
        }
        
    }, [])
    
    const doSetData = (newData) => {
        setOptions(newData)
        
        let num = newData.length
        let divisors = [];
        for (let i = 1; i <= num; i++) {
            if (num % i === 0 && i !== 1) {
                divisors.push(i);
            }
        }
        setPlayerDivisors(divisors)
        
        doSetPlayerCount(divisors[Math.round(divisors.length / 2) - 1])
        
    }
    
    const doSetPlayerCount = (newCount) => {
        setPlayerCount(newCount)
        if (newCount > players.length) {
            setPlayers([...players, ...new Array(newCount - players.length).fill("")])
            console.log([...players, ...new Array(newCount - players.length).fill("")])
        } else {
            console.log(players)
        }

    }
    
    
    return (
        <div className="page-container">
            <Card title="选择模式">
                <div style={{display: "flex", gap: 20, alignItems: "center", paddingBottom: 20}}>
                    <Select
                        style={{width: 200}}
                        value={selectOption}
                        onChange={(e) => {
                            setSelectOption(e)
                            if (e === "自定义") {
                                doSetData([])
                                setTempData("")
                                setEditOption(true)
                                return
                            }
                            doSetData(allOptions[e].data)
                        }}
                        options={[...allOptions.map((item, index) => ({label: item.key, value: index})), {label: "自定义", value: "自定义"}]}
                    />
                    <div>总共：{options.length}个</div>
                    <div onClick={() => {
                        setTempData(options.join("\n"))
                        setEditOption(true)
                    }} style={{color: "#6eddff", cursor: "pointer"}}>
                        编辑
                        <EditOutlined/>
                    </div>
                </div>
                <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: "10px 20px"}}>
                    {options.map(item => <div style={{borderRadius: 5, padding: "5px 10px", backgroundColor: darkMode ? "#444" : "#eee", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                        {item}
                    </div>)}
                </div>
            </Card>
            <Card title="录入玩家"
                  extra={[<Button onClick={() => {
                      setPlayers(new Array(playerCount).fill(""))
                  }}>重置</Button>, <Button style={{marginLeft: 20}} onClick={() => {
                      setTempPlayers(players.join("\n"))
                      setEditPlayer(true)
                  }}>从文本框输入</Button>]}>
                <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(130px, 1fr))", gap: 20, paddingBottom: 20}}>
                    {playerDivisors.map(item => (
                        <div
                            style={{
                                flex: 1,
                                backgroundColor: playerCount === item ? "#6eddff" : ( darkMode ? "#444" : "#eee"),
                                textAlign: "center",
                                padding: 10,
                                borderRadius: 10,
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                doSetPlayerCount(item)
                            }}
                        >
                            <div style={{color: playerCount === item ? "#fff" : ( darkMode ? "#fff" : "#000")}}>{item}人</div>
                            <div style={{color: playerCount === item ? "#fff" : "#aaa"}}>每人分配{options.length / item}队</div>
                        </div>
                    ))}
                </div>
                <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))", gap: "20px 20px"}}>
                    {Array.from({length: playerDivisors[playerDivisors.length - 1]}).map((_, index) => (
                        <div style={{display: index < playerCount ? "flex" : "none", alignItems: "center", gap: 5}}>
                            <div style={{width: 20, textAlign: "right"}}>{index + 1}.</div>
                            <Input value={players[index]} onChange={(e) => {
                                let newList = [...players]
                                newList[index] = e.target.value
                                setPlayers(newList)
                            }}></Input>
                        </div>
                    ))}
                </div>
                <Button block type="primary" style={{margin: "20px 0 5px"}} onClick={() => {
                    setPlayers(players.slice(0, playerCount));
                    navigate("/listResult")
                }}>开始随机</Button>
                <div style={{textAlign: "right", color: "#888"}}>* 留空将在随机时候轮空</div>
            </Card>
            <Modal open={editOption} title="编辑选项" onCancel={() => setEditOption(false)} onOk={() => {
                doSetData(tempData.split("\n").filter(item => item !== ""))
                setEditOption(false)
            }}>
                <Input.TextArea value={tempData} autoSize onChange={(e) => setTempData(e.target.value)}/>
            </Modal>
            
            <Modal open={editPlayer} title="编辑玩家" onCancel={() => setEditPlayer(false)} onOk={() => {
                setPlayers(tempPlayers.split("\n").filter(item => item !== ""))
                setEditPlayer(false)
            }}>
                <Input.TextArea value={tempPlayers} autoSize onChange={(e) => setTempPlayers(e.target.value)}/>
            </Modal>
        </div>
    );
}

export default Page;
