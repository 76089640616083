import React from "react";

import Layout from "../common/layout";
import Result404 from "../common/result/404"
import Login from "../common/user/login2";
import Home from "../pages/home";
import NewsList from "../pages/news/list";
import NewsEdit from "../pages/news/edit";

import Header from "../common/layout/component/header";


import {
    UnorderedListOutlined,
    CloudOutlined,
    DashboardOutlined,
    ExperimentOutlined,
    FormOutlined,
    FundOutlined, HighlightOutlined, StarOutlined, ToolOutlined, HomeOutlined
} from "@ant-design/icons";
import {Watermark} from "antd";
import CardGraph from "../pages/card";
import CardGraph2 from "../pages/card/index2";
import CardGraph3 from "../pages/card/index3";
// import CardGraph from "../pages/card/complete";
import TradeQuery from "../pages/tradeQuery";
import PlayerGraph from "../pages/player";
import PlayerDetail from "../pages/player/detail";

import ToolsLayout from "../pages/tools/layout"
import ToolsList from "../pages/tools/list"
import ToolsListResult from "../pages/tools/listResult"
import NewsDetail from "../pages/player/newsDetail";


// 路由表
export const routes = [
    {
        path: "/",
        element: <ToolsLayout/>,
        children: [
            {
                index: true,
                element: <ToolsList/>,
                icon: <DashboardOutlined/>,
                label: "Dashboard"
            },
            {
                path: "listResult",
                name: "List Random Result",
                icon: <HomeOutlined/>,
                element: <ToolsListResult/>,
            },
            {
                path: "*",
                element: <Result404/>,
            }
        ]
    },
    {
        path: "/page/card",
        element: <CardGraph3/>
    }, {
        path: "/page/newsDetail/:name/:id",
        element: <NewsDetail/>
    },
    {
        path: "/page/player",
        element: <PlayerGraph/>
    },
    {
        path: "/page/playerDetail",
        element: <PlayerDetail/>
    },
    {
        path: "/page/trade",
        element: <TradeQuery/>
    },
    {
        path: "/login",
        element: <Login/>,
    },
    {
        path: "*",
        element: <Result404/>,
    }
];
