import {request} from "./request";

export const  cardPlayerList = async body => request("/card/playerList", body)
export const  cardPlayerList2 = async body => request("/card/player/stats?season=2024&seasonType=2", body)
export const  cardPlayerGraph = async body => request("/card/player/graph", body)
export const  cardListQuery = async body => request("/card/list/query", body)
export const  cardTableQuery = async body => request("/card/table/test", body)
export const  playerDetailQuery = async body => request("/card/player/detail", body)

export const  saveNews = async body => request("/news/save", body)
export const  listNews = async body => request("/news/list", body)
export const  newsOption = async body => request("/news/edit/options", body)
export const  getNewsDetail = async body => request("/news/detail", body)