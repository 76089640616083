import {playerDetailQuery} from "../../config/api";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";

const NewsDetail = () => {
    const [data, setData] = useState()
    const [relevantPlayer, setRelevantPlayer] = useState([])
    const {name, id} = useParams()
    const navigate = useNavigate()
    console.log(name, id)
    useEffect(() => {
        playerDetailQuery({query: name, days: 7}).then(r => {
            const newsDetail = r.newsList.find(item => item.id === id)
            console.log(newsDetail)
            if (newsDetail.relevantPlayer) {
                newsDetail.relevantPlayer.forEach(async (item) => {
                    const result = await playerDetailQuery({query: item, days: 7})
                    setRelevantPlayer([...relevantPlayer, result.playerDetail])
                })
            }
            setData(newsDetail)
        })
    }, [])
    return <div>
        <div style={{backgroundColor: "#0F1134",color:"white",boxSizing:"border-box",height:"100vh",overflow: "auto",display: "flex", flexDirection: "column", padding: 30, gap: 20}}>
            <div style={{fontSize: 17, fontWeight: 800}}>{data?.title}</div>
            <div style={{fontSize: 14, color: "gray"}}>{dayjs(data?.createDate).format('YYYY-M-DD')}</div>
            <img src={data?.images[0]} style={{width: "100%"}} alt=""></img>
            <div style={{fontSize: 15}}>
                {/*{*/}
                {/*    [...data?.content].map(item => {*/}
                {/*        return <div style={{textIndent:64}}>{item}</div>*/}
                {/*    })*/}
                {/*}*/}
                {data?.content.split('\n').map(item => {
                    return <div style={{textIndent: 30}}>{item}</div>
                })}
            </div>
            {
                data?.images.map((item, index) => {
                    if (index !== 0) {
                        return <div style={{width: "100%"}}>
                            <image src={{item}} style={{width: "100%"}}></image>
                        </div>
                    }
                })
            }
            {
                data?.relevantPlayer && <div>
                    <div style={{color: "#98EEF4", fontSize: 16}}>相关球员</div>
                    <div style={{display: "flex", fontSize: 14, marginTop: 10, gap: 10, flexWrap: "wrap"}}>
                        {
                            relevantPlayer.map(item => {

                                return <div onClick={() => navigate(`/page/playerDetail?${item.name}`)} style={{display: "flex",alignItems:"center",flexDirection: "column"}}>
                                    <div
                                        style={{borderRadius: "50%", width: 60, height: 60,border: "1px solid #aaa",overflow: "hidden",backgroundColor: "#fff",display: "flex",flexDirection: "column"}}>
                                        <img src={item.peopleImage}
                                             style={{width: 80,marginLeft: -10}} alt={item.name}/>
                                    </div>
                                    <div>{item.name}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
            }
            {
                data?.relevantTeam && <div>
                    <div style={{color: "#98EEF4", fontSize: 16}}>相关球队</div>
                    <div style={{display: "flex", fontSize: 14, marginTop: 10, gap: 10, flexWrap: "wrap"}}>
                        {
                            data.relevantTeam.map(item => {
                                return <div
                                    style={{
                                        padding: '2px 10px',
                                        borderRadius: 8,
                                        backgroundColor: "#B6C6D2",
                                        color: "black",
                                        textAlign: "center"
                                    }}>
                                    {item}
                                </div>
                            })
                        }
                    </div>
                </div>
            }
        </div>
    </div>
}
export default NewsDetail