import {useEffect, useState} from "react";
import {useRequest} from "ahooks";
import {cardTableQuery} from "../../config/api";
import { Line } from '@ant-design/plots';
import { format } from 'fecha';
import {createRoot} from "react-dom/client";
import {DeleteOutlined, DeleteRowOutlined} from "@ant-design/icons";
import {Spin, Tag} from "antd";

const cardDist = [
    "银折 RC",
    "银折 RC PSA10",
    "国宝 RPA /99"
]
// const colors = [
//     "#5288eb",
//     "#58ca9e",
//     "#5a6a8c",
//     "#e7b31f",
//     "#675be9",
//     "#64bee0"
// ]

const colors = [
    "#fdef00",
    "#e40012",
    "#e2007e",
    "#54b8e8",
    "#23ab38",
    "#f19700"
]

const DataGraph = () => {

    const {runAsync: doCardTableQuery, loading: cardTableQueryLoading} = useRequest(cardTableQuery, {manual: true});

    const [config, setConfig] = useState({})
    const [data, setData] = useState({})
    const [cardList, setCardList] = useState([])


    useEffect(() => {
        document.title = "卡价对比"
        let queryMessage = location.search.slice(1).replaceAll("%20", " ").replaceAll("%7C", "|")
        let cardList = []
        if (queryMessage !== "") {
            let arr = queryMessage.split("|")
            arr.forEach(item => {
                let items = item.split(":")
                cardList.push({player: items[0], cardType: cardDist[items[1]]})
            })

        } else {
            cardList = [
                {player: "Jalen Green", cardType: "银折 RC"},
                {player: "Luka Doncic", cardType: "银折 RC"}
            ]
        }

        // let colorDict = {}
        // cardList.map((item, index) => colorDict[item.player + " " + item.cardType] = colors[index])
        
        requestData(cardList)
        
    }, [])
    
    const requestData = (cardList) => {
        doCardTableQuery({cardList}).then(r => {
            setData(r)
            
            // 使用reduce函数，combinedKeys对象来去重，并维持顺序
            const uniqueList = r?.tableData?.reduce((accumulator, item) => {
                
                const combinedKey = item.player + '|' + item.cardType;
                
                // 检查combinedKey在combinedKeys对象中是否存在
                if (!accumulator.combinedKeys[combinedKey]) {
                    // 如果不存在，则添加到accumulator的结果数组中
                    accumulator.result.push({ player: item.player, cardType: item.cardType });
                    // 在combinedKeys对象中标记该组合键为已存在
                    accumulator.combinedKeys[combinedKey] = true;
                }
                return accumulator;
            }, { combinedKeys: {}, result: [] }).result;
            // console.log(uniqueList)
            setCardList(uniqueList)
            
            if (r?.tableData.length > 0) {
                let data = r?.tableData
                data.sort((a, b) => {
                    let dateA = new Date(a.date);
                    let dateB = new Date(b.date);
                    return dateA - dateB; // 升序排序
                    // return dateB - dateA; // 降序排序
                });
                
                setConfig({
                    data,
                    xField: (d) => new Date(d.date),
                    yField: 'price',
                    axis: { x: { title: false, size: 40 }, y: { title: false, size: 36 } },
                    slider: {
                        x: { labelFormatter: (d) => format(d, 'M/D') },
                        // y: { labelFormatter: '~s' },
                    },
                    // colorField: (e) => colorDict[e.player],
                    colorField: "card",
                    // seriesField: "player"
                    scale: {
                        line: 10,
                        color: {
                            range: colors,
                        },
                    },
                })
            }
        })
    }
    
    const deleteItem = (index) => {
        let newArr = cardList
        newArr.splice(index, 1);
        requestData(newArr)
    }

    return  <Spin spinning={cardTableQueryLoading} tip="正在从数据库调取对比数据" >
        <div style={{backgroundColor: "#141414", minHeight: "100vh",boxSizing:"border-box", padding: "0 0 50px" }}>
        <div style={{color: "#fff", textAlign:"center"}}>
            {data.tableData ? (data.tableData.length === 0 ? "数据为空" : null) : null}
        </div>
            <div style={{paddingTop: 10}}>
                <Line
                    autoFit={true}
                    responsive={true}
                    theme="dark"
                    legend={false}
                    {...config}
                />
                {
                    data.tableData ? <div
                        style={{
                            color: "rgb(255,255,255,0.3)",
                            margin: "-28px 10px 0 0 ",
                            textAlign: "right",
                            fontSize: '10px',
                            padding: "5px 0"
                        }}>数据来源于全网，供参考
                    </div> : null
                }
            </div>

        <div style={{display: "flex", flexDirection: "column", gap: 20, padding: "0 20px"}}>
            {cardList.map((item, index) => {
                return (
                    <div style={{padding: 10, backgroundColor: "#fff", display: "flex", alignItems: "center", borderRadius: 10, border: "5px solid " + colors[index]}}>
                        <div style={{flex: 1}}>{item.player}</div>
                        <div style={{flex: 1}}>{item.cardType}</div>
                        <div style={{width: 80, display: "flex", justifyContent:"space-between"}}>
                            {item.cardType.includes("PSA10") ? <Tag color="processing">PSA10</Tag> : <Tag>RAW</Tag>}
                            <div onClick={() => {deleteItem(index)}}><DeleteOutlined/></div>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
</Spin>
}
export default DataGraph
