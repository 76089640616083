import React, {useContext, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {PageContainer, ProConfigProvider, ProLayout} from "@ant-design/pro-components";
import {routes as reactRoutes} from "../../config/routes";
import {ConfigProvider, Switch} from "antd";
import {CheckOutlined, CloseOutlined, MoonOutlined, SunOutlined} from "@ant-design/icons";
import {DataContext} from "../../store/DataContext";
import zhCN from "antd/locale/zh_CN";
const Page = () => {

    const navigate = useNavigate();
    const { darkMode, setDarkMode } = useContext(DataContext);
    const [routes, setRoutes] = useState([])
    
    useEffect(() => {
        
         setRoutes(reactRoutes[0].children.map(item => {
             return {
                 path: item.path,
                 name: item.name,
                 icon: item.icon,
                 routes: item?.children?.map(item => {
                     return {
                         path: item.path,
                         name: item.name,
                         icon: item.icon,
                     }
                 })
             }
         }))
            
    }, [])
    const onChangeMenu = (item)  => {
        navigate(item)
    }
    
    
    return (
        <ConfigProvider theme={{token: {colorPrimary: "#6eddff"}}} locale={zhCN}>
            <ProConfigProvider dark={darkMode}>
                <ProLayout
                    title="CardCool Tools"
                    logo="https://10kv.oss-cn-shanghai.aliyuncs.com/cardcool/logo/logo2.png"
                    route={{routes}}
                    onMenuHeaderClick={() => onChangeMenu( "")}
                    menuProps={{
                        onClick: ({key}) => {
                            onChangeMenu(key)
                        }
                    }}
                    layout="top"
                >
                    <PageContainer
                        extra={[
                                <Switch
                                    checkedChildren={<MoonOutlined />}
                                    unCheckedChildren={<SunOutlined />}
                                    checked={darkMode}
                                    onChange={(e) => {
                                        setDarkMode(e)
                                        localStorage.setItem("toolsDarkMode", e)
                                    }}
                                />
                            ]}
                    >
                        <Outlet />
                    </PageContainer>
                </ProLayout>
            </ProConfigProvider>
        </ConfigProvider>
    );
}

export default Page;
