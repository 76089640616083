import {Chart} from '@antv/g2';
import {ConfigProvider, Empty, Popover, Spin, theme} from 'antd';
import {useEffect, useState} from "react";
import './index.css'
import {useRequest} from "ahooks";
import {CalendarOutlined, SettingOutlined,} from "@ant-design/icons";
import {useLocation} from "react-router-dom";
import {cardPlayerGraph} from "../../config/api";
import {format, subDays} from 'date-fns';


const PlayerGraph = () => {
    const [data, setData] = useState(null)
    const [player, setPlayer] = useState("")
    const [graphType, setGraphType] = useState('indexData') //indexData countData averageData
    const [daysRecent, setDaysRecent] = useState(14)
    const [daysRange, setDaysRange] = useState(7)
    const {runAsync: doCardPlayerGraph, loading: cardPlayerGraphLoading} = useRequest(cardPlayerGraph, {manual: true});
    const location = useLocation();

    useEffect(() => {
        let queryMessage = location.search.slice(1).replaceAll("%20", " ")
        setPlayer(queryMessage)
        renderGraph(queryMessage, daysRecent, daysRange, graphType)
    }, [])


    const renderGraph = (name, recent, range, type) => {

        const needRequestAPI = ((data === null) || (recent !== daysRecent) || (range !== daysRange))
        setDaysRecent(recent)
        setDaysRange(range)
        setGraphType(type)
        if (needRequestAPI) {
            doCardPlayerGraph({player: [name], daysRecent: recent, daysRange: range}).then(r => {
                const sortDate = (data) => {
                    return data.sort((a, b) => {
                        let dateA = new Date(a.date);
                        let dateB = new Date(b.date);
                        if (dateA < dateB) {
                            return -1; // a 在前
                        } else if (dateA > dateB) {
                            return 1; // b 在前
                        } else {
                            return 0; // 日期相同
                        }
                    })
                }
                let amountData = sortDate(r.amountData)
                let averageData = sortDate(r.averageData)
                let countData = sortDate(r.countData)
                let indexData = sortDate(r.indexData)
                let data1 = {...r, amountData,averageData,countData,indexData}
                setData(data1)
                draw(data1[type], type)
            })
        } else {
            draw(data[type], type)
        }
    }

    const graphType2Label = (type) => {
        let typeY
        switch (type) {
            case "indexData":
                typeY = '指数';
                break;
            case "countData":
                typeY = '交易量';
                break;
            case "averageData":
                typeY = '均价';
                break;
            default:
                break;
        }
        return typeY
    }

    const draw = (sortData, type) => {
        // let sortData = [...data].reverse()
        let tableData = []
        let min = 99999999
        let max = 0
        let typeY = graphType2Label(type)
        sortData.map((item, index) => {
            if (item.value > max) {
                max = item.value
            }
            if (item.value < min) {
                min = item.value
            }
            let recent = [sortData[index].value]
            if (index !== 0) {
                recent.push(sortData[index - 1].value)
            }
            if (index !== sortData.length - 1) {
                recent.push(sortData[index + 1].value)
            }
            recent.shift()
            recent.push(item.value)
            tableData.push({
                date: item.date.replaceAll("-", ".").replace("2024.", ""),
                index: item.value,
                reference: recent.reduce((acc, val) => acc + val, 0) / recent.length,
                [typeY]: typeY === '均价' ? item.value.toFixed(2) : item.value,
                "趋势": (recent.reduce((acc, val) => acc + val, 0) / recent.length).toFixed(0),
                player: item.player
            })
        })

        // tableData.sort((a, b) => {
        //     let dateA = new Date(a.date);
        //     let dateB = new Date(b.date);
        //     return dateA - dateB; // 升序排序
        //     // return dateB - dateA; // 降序排序
        // });
        // tableData = tableData.slice(0)
        const chart = new Chart({
            container: 'container',
            autoFit: true,
            theme: 'classicDark',
        });
        chart.legend(false);

        chart
            .axis('x',
                {
                    title: false,
                    size: 20,
                    labelAutoHide: true,
                    line: true,
                    // lineStroke: "#f00",
                    lineStrokeOpacity: 1,
                    lineLineWidth: 1,

                })
            .axis('y',
                {
                    title: false,
                    line: true,
                    lineStrokeOpacity: 1,
                    lineLineWidth: 1,
                })
            .scale('y', {
                type: 'linear',
                // domainMin: Math.floor(min * 0.9 / 100) * 100,
                domainMin: 0,
                domainMax: Math.ceil(max * 1.1 / 100) * 100
            })
            .encode('shape', 'smooth') // 'area', 'smooth', 'hvh', 'vh', 'hv'


        if (type === "indexData") {
            chart.area()
                .data(tableData)
                .encode('x', 'date')
                // .encode('y', ['index', Math.floor(min * 0.9 / 100) * 100])
                // .encode('y', ['index', 0])
                .encode('y', ['趋势', 0])
                .style('opacity', 0.2)
                .animate('enter', {type: 'fadeIn', duration: 1000})
                .tooltip(false)


            chart.line()
                .data(tableData)
                .encode('x', 'date')
                // .encode('y', 'index')
                .encode('y', '趋势')
                .style('lineWidth', 1.5)
                .animate('enter', {type: 'pathIn', duration: 1000});

            chart.line()
                .data(tableData)
                .encode('x', 'date')
                // .encode('y', 'reference')
                .encode('y', typeY)
                .style('lineWidth', 1.5)
                .encode("color", "#666")
                .animate('enter', {type: 'pathIn', duration: 1000});

            chart.point()
                .data(tableData)
                .encode('shape', 'point')
                .encode('x', 'date')
                // .encode('y', 'index')
                .encode('y', '趋势')
                .tooltip(false)
                .animate('enter', {type: 'pathIn', duration: 1000});
        } else {
            chart.area()
                .data(tableData)
                .encode('x', 'date')
                .encode('y', typeY)
                .style('opacity', 0.2)
                .animate('enter', {type: 'fadeIn', duration: 1000})
                .tooltip(false)

            chart.line()
                .data(tableData)
                .encode('x', 'date')
                .encode('y', typeY)
                .style('lineWidth', 1.5)
                .animate('enter', {type: 'pathIn', duration: 1000});

            chart.point()
                .data(tableData)
                .encode('shape', 'point')
                .encode('x', 'date')
                .encode('y', typeY)
                .tooltip(false)
                .animate('enter', {type: 'pathIn', duration: 1000});
        }

        chart.render();
    }

    const changeDateRange = (i) => {
        setDaysRange(i)
        requestTableData(player, daysRecent, i)
    }

    const changeDateRecent = (i) => {
        setDaysRecent(i)
        if (i === 7) {
            setDaysRange(1)
            renderGraph(player, i, 1, graphType)
        } else {
            renderGraph(player, i, 7, graphType)
        }
    }

    const daysAgo = (i) => {
        const someDaysAgo = subDays(new Date(), i);
        return format(someDaysAgo, 'yyyy.MM.dd');
    };

    return <>
        <Spin spinning={cardPlayerGraphLoading}>
            <div style={{backgroundColor: "#151515", color: "#fff"}}>
                <div style={{display: 'flex', padding: "10px 0px 10px"}}>
                    <ConfigProvider theme={{algorithm: theme.darkAlgorithm}}>
                        <Popover
                            placement="bottom"
                            title=""
                            icon={false}
                            content={
                                <div style={{width: "70vw", padding: 10}}>
                                    <div style={{marginBottom: 5}}>日期区间</div>
                                    <div className="group">
                                        <div style={{backgroundColor: daysRecent === 7 ? "#555" : ""}}
                                             onClick={() => changeDateRecent(7)}>7
                                        </div>
                                        <div style={{backgroundColor: daysRecent === 14 ? "#555" : ""}}
                                             onClick={() => changeDateRecent(14)}>14
                                        </div>
                                        <div style={{backgroundColor: daysRecent === 30 ? "#555" : ""}}
                                             onClick={() => changeDateRecent(30)}>30
                                        </div>
                                        <div style={{backgroundColor: daysRecent === 60 ? "#555" : ""}}
                                             onClick={() => changeDateRecent(60)}>60
                                        </div>
                                        <div style={{backgroundColor: daysRecent === 90 ? "#555" : ""}}
                                             onClick={() => changeDateRecent(90)}>90
                                        </div>
                                        <div style={{backgroundColor: daysRecent === -1 ? "#555" : ""}}
                                             onClick={() => changeDateRecent(-1)}>全部
                                        </div>
                                    </div>
                                    {/*<div style={{marginTop: 10}}>Date Range</div>*/}
                                    {/*<div className="group">*/}
                                    {/*    <div style={{backgroundColor: daysRange === 1 ? "#555" : ""}} onClick={() => changeDateRange(1)}>Day</div>*/}
                                    {/*    <div style={{backgroundColor: daysRange === 7 ? "#555" : ""}} onClick={() => changeDateRange(7)}>Week</div>*/}
                                    {/*    <div style={{backgroundColor: daysRange === 30 ? "#555" : ""}} onClick={() => changeDateRange(30)}>Month</div>*/}
                                    {/*</div>*/}
                                </div>
                            }
                        >
                            <div style={{display: "flex",}}>
                                <div style={{
                                    display: "flex",
                                    gap: 10,
                                    border: "1px solid #666",
                                    padding: "0px 8px",
                                    borderRadius: 3,
                                    alignItems: "center"
                                    // boxShadow:" 0 0 20px -5px #fff"

                                }}>
                                    <div><CalendarOutlined/></div>
                                    {/*<div>{daysAgo(daysRecent)} ~ {daysAgo(1)}</div>*/}
                                    <div
                                        style={{fontSize: "12px"}}>{daysRecent === -1 ? "全部" : `最近${daysRecent}天`}</div>
                                </div>
                                {/*<div style={{flex: 1}}></div>*/}

                                {/*<div><SettingOutlined/></div>*/}
                            </div>
                        </Popover>
                    </ConfigProvider>

                    <div style={{flex: 1}}></div>

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        lineHeight: '18px',
                        fontSize: "12px"
                    }}>
                        {["indexData", "countData", "averageData"].map((item, index) => (
                            <div onClick={() => renderGraph(player, daysRecent, daysRange, item)} style={{
                                width: '50px',
                                whiteSpace: "nowrap",
                                borderRadius: index === 0 ? '20px 0 0 20px' : (index === 1 ? "0 0 0 0" : "0 20px 20px 0"),
                                padding: '3px 0',
                                backgroundColor: graphType === item ? '#2f3561' : '#222'
                            }}>
                                {graphType2Label(item)}
                            </div>
                        ))}
                    </div>
                </div>
                <div id="container" style={{height: 200}}>
                    {data === null ? <div></div> : (data?.[graphType]?.length === 0 ?
                        <Empty style={{marginTop: 50}}/> : null)}
                    {/*{data.length === 0? <Empty style={{marginTop: 50}}/> : null}*/}
                </div>
                {
                    data !== null ? <div
                        style={{
                            color: "rgb(255,255,255,0.3)",
                            margin: "-12px 14px 0 0 ",
                            textAlign: "right",
                            fontSize: '10px'
                        }}>数据来源于全网，供参考
                    </div> : null
                }
            </div>
        </Spin>
    </>
}
export default PlayerGraph