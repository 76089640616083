import {Chart} from '@antv/g2';
import {Input, Button, Modal, Select, Pagination, Spin, Tag, message, Empty} from 'antd';
import {useEffect, useState} from "react";
import axios from "axios";
import {useRequest} from "ahooks";
import {CheckCircleOutlined, DeleteOutlined, PlusCircleOutlined, SyncOutlined} from "@ant-design/icons";


const cardRequest = async (url, body) => {
    // return (await axios.post("https://aiviz.cn/api/card/" + url, body,
    return (await axios.post("https://aiviz.cn/api/card/" + url, body,
        {
            headers: {
                // "X-Access-Token": token
            }
        })).data.object
}

const cardPlayerList = async body => cardRequest("playerList", body)
const cardListQuery = async body => cardRequest("list/query", body)
const cardTableQuery = async body => cardRequest("table/test", body)

const cardList = [
    {name: "银折 RC PSA10", key: "1"},
    {name: "国宝 RPA /99", key: "2"},
    {name: "国宝 RPA BGS", key: "3"},
    {name: "国宝 RPA PSA", key: "4"},
    {name: "新秀 donruss 明日签", key: "5"}]

const TradeQuery = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [displayPlayerList, setDisplayPlayerList] = useState([])
    const [pageDisplayPlayerList, setPageDisplayPlayerList] = useState([])
    const {runAsync: doCardListQuery, loading: cardListQueryLoading} = useRequest(cardListQuery, {manual: true});
    useEffect(() => {
        document.title = "TradeQuery"
    },[])
    const pageChange = (page, pageSize) => {
        // console.log(page)
        // console.log(pageSize)
        setCurrentPage(page)
        setPageDisplayPlayerList(displayPlayerList.slice((page - 1) * 10, page * 10))
    }
    const search = () => {
        doCardListQuery({query: document.getElementById('searchInput').value}).then(r => {
            let data = []
            for (let i = 0; i < 100; i++) {
                data = [...data, ...r]
            }
            setDisplayPlayerList(data)
            setPageDisplayPlayerList(r.splice(0, 10))
        })
    }
    const Card = ({name, productUrl, imageUrl, price, currency, dateTime}) => {
        return <div style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            alignItems: "center",
            paddingBottom: 10,
            borderBottom: "1px solid #ccc"
        }}>
            {/*<img style={{width: "100px"}} src="https://10kv.oss-cn-shanghai.aliyuncs.com/image/78fbc0bb-d1df-4da9-a599-0bd0fddc2f51.jpg" alt=""/>*/}
            <img style={{width: "100px"}} src={imageUrl} alt=""/>
            <div style={{
                display: "flex",
                padding: "0 10px",
                flexDirection: "column",
                gap: 5,
                flex: 1,
            }}>
                <div>name: {name}</div>
                <div>currency: {currency}</div>
                <div>price: {price}</div>
                <div>dataTime: {dateTime}</div>
                {/*<div>productUrl: <a href={productUrl}>{productUrl}</a></div>*/}
            </div>
        </div>
    }
    return <>
        <Spin spinning={cardListQueryLoading}>
            <div className="card" style={{padding: "20px"}}>
                <div style={{display: "flex", width: "100%", gap: "5px"}}>
                    <Input id="searchInput" placeholder="输入查询内容" onPressEnter={search}/>
                    <Button onClick={search}>查询</Button>
                </div>
                {
                    displayPlayerList.length !== 0 ?
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                gap: "10px",
                                padding: "10px"
                            }}>
                            {
                                pageDisplayPlayerList.map((item, index) => {
                                    return <Card {...item} key={index}/>
                                })
                            }
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <Pagination
                                    defaultCurrent={1}
                                    onChange={pageChange}
                                    current={currentPage} total={displayPlayerList.length}
                                    pageSize={10}
                                /></div>
                        </div> :
                        <div>
                            <div style={{padding: "0 20px"}}>
                                <ul style={{padding: "5px 5px 5px 5px", margin: 0}}>
                                    <li>To search for multiple variations of the same term enclose them in brackets and
                                        separate them by a comma.<br/><i>(2019-20, 19-20)</i> or <i>(PSA, BGS)</i></li>
                                    <li>Use a minus "-" sign to exclude terms from the search.<br/><i>(2020-21, 20-21)
                                        Lamelo Ball -box -case -break</i></li>
                                    <li>Use <i>&amp;</i> to match only terms of that pattern.<br/><i>Charizard
                                        PSA&amp;10</i></li>
                                </ul>
                            </div>
                        </div>
                }
            </div>
        </Spin>
    </>
}
export default TradeQuery