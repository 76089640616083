import {
    LoginFormPage,
    ProConfigProvider,
    ProFormCaptcha,
    ProFormCheckbox,
    ProFormText
} from "@ant-design/pro-components";
import {useState} from "react";
import {Button, Divider, Space, Tabs, theme, message, Popconfirm} from "antd";
import {
    AlipayOutlined, AntCloudOutlined, DropboxOutlined,
    LockOutlined,
    MobileOutlined,
    TaobaoOutlined,
    UserOutlined,
    WeiboOutlined
} from "@ant-design/icons";
import enUS from "antd/locale/en_US";
import {doLogin} from "../../config/request";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const iconStyles = {
    color: 'rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
    verticalAlign: 'middle',
    cursor: 'pointer',
};

const Page = () => {
    const [loginType, setLoginType] = useState('account');
    const { token } = theme.useToken();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div
            style={{
                height: '100vh',
                background: 'url(https://img.zcool.cn/community/015b4e5b87724ca80120245ca7dd24.JPG@1280w_1l_2o_100sh.jpg) no-repeat',
                backgroundPosition: "center center",
                backgroundSize: "auto 100%"
            }}
        >
            <LoginFormPage
                onFinish={(e) => {
                    doLogin({...e, loginType: "account"}, dispatch)
                        .then(r => {
                            if (r) {
                                message.success("登录成功").then()
                                navigate("/");
                            } else {
                                message.error("用户名或密码错误").then()
                            }
                        })
                }}
                disabled={loginType === "mobile"}
                initialValues={{
                    autoLogin: true,
                }}
                submitter={{
                    searchConfig: {
                        submitText: loginType === "mobile" ? "No Support" : 'Login',
                    }
                }}
                // backgroundImageUrl="https://images.pexels.com/photos/1097456/pexels-photo-1097456.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                backgroundImageUrl="https://img.zcool.cn/community/015b4e5b87724ca80120245ca7dd24.JPG@1280w_1l_2o_100sh.jpg"
                // backgroundImageUrl="https://www.ikea.cn/images/bb/36/bb365970d6a4d3cb044ce50311891c91.jpg?imwidth=3600"
                logo="https://gw.alipayobjects.com/zos/antfincdn/FLrTNDvlna/antv.png"
                // backgroundVideoUrl="https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr"
                title="AIVIZ "
                containerStyle={{
                    backgroundColor: 'rgba(30, 30, 30,0.65)',
                    backdropFilter: 'blur(4px)',
                }}
                subTitle="AIVIZ Online Visual Monitoring Platform"
                activityConfig={window.innerHeight > 850 ?
                    {
                        style: {
                            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
                            color: token.colorTextHeading,
                            borderRadius: 8,
                            backgroundColor: 'rgba(255,255,255,0.25)',
                            backdropFilter: 'blur(4px)',
                        },
                        title: 'AIVIZ Design MP',
                        subTitle: 'Design Your Dream Home with Just a Click',
                        action: (
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <Popconfirm
                                    placement="top"
                                    title={<div style={{textAlign: "center"}}>AIVIZ MP</div>}
                                    okButtonProps={{style: {display: "none"}}}
                                    icon={null}
                                    showCancel={false}
                                    description={<div><img style={{width: 200, borderRadius: 20}} alt="" src="https://10kv.oss-cn-shanghai.aliyuncs.com/aiviz/poster/mp.jpg"/></div>}
                                >
                                    <Button
                                        size="large"
                                        style={{
                                            borderRadius: 20,
                                            background: token.colorBgElevated,
                                            color: token.colorPrimary,
                                            width: 120,
                                        }}
                                    >
                                        Try It Now
                                    </Button>
                                </Popconfirm>
                            </div>
                        ),
                    } : null}
                actions={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Divider plain>
                            <span
                                style={{
                                    color: token.colorTextPlaceholder,
                                    fontWeight: 'normal',
                                    fontSize: 14,
                                }}
                            >
                              OR
                            </span>
                        </Divider>
                        <Space align="center" size={24} style={{cursor: "pointer"}}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    height: 40,
                                    width: 40,
                                    border: '1px solid ' + token.colorPrimaryBorder,
                                    borderRadius: '50%',
                                }}
                            >
                                <AntCloudOutlined style={{ ...iconStyles, color: '#1677FF' }} />
                            </div>
                            <div style={{color: token.colorTextHeading}}>Ebai SSO Platform</div>
                        </Space>
                    </div>
                }
            >
                <Tabs
                    centered
                    activeKey={loginType}
                    onChange={(activeKey) => setLoginType(activeKey)}
                >
                    <Tabs.TabPane key={'account'} tab={'Account Login'} />
                    <Tabs.TabPane key={'mobile'} tab={'Mobile Login'} />
                </Tabs>
                {loginType === 'account' && (
                    <>
                        <ProFormText
                            name="userName"
                            fieldProps={{
                                size: 'large',
                                prefix: (
                                    <UserOutlined
                                        style={{
                                            color: token.colorText,
                                        }}
                                        className={'prefixIcon'}
                                    />
                                ),
                            }}
                            placeholder={'UserName'}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入用户名!',
                                },
                            ]}
                        />
                        <ProFormText.Password
                            name="password"
                            fieldProps={{
                                size: 'large',
                                prefix: (
                                    <LockOutlined
                                        style={{
                                            color: token.colorText,
                                        }}
                                        className={'prefixIcon'}
                                    />
                                ),
                            }}
                            placeholder={'Password'}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入密码！',
                                },
                            ]}
                        />
                    </>
                )}
                {loginType === 'mobile' && (
                    <div>
                        <ProFormText
                            fieldProps={{
                                size: 'large',
                                prefix: (
                                    <MobileOutlined
                                        style={{
                                            color: token.colorText,
                                        }}
                                        className={'prefixIcon'}
                                    />
                                ),
                            }}
                            name="mobile"
                            placeholder={'Mobile Number'}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入手机号！',
                                },
                                {
                                    pattern: /^1\d{10}$/,
                                    message: '手机号格式错误！',
                                },
                            ]}
                        />
                        <ProFormCaptcha
                            fieldProps={{
                                size: 'large',
                                prefix: (
                                    <LockOutlined
                                        style={{
                                            color: token.colorText,
                                        }}
                                        className={'prefixIcon'}
                                    />
                                ),
                            }}
                            captchaProps={{
                                size: 'large',
                            }}
                            placeholder={'Captcha'}
                            captchaTextRender={(timing, count) => {
                                if (timing) {
                                    return `${count} ${'获取验证码'}`;
                                }
                                return 'Send Captcha';
                            }}
                            name="captcha"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入验证码！',
                                },
                            ]}
                            onGetCaptcha={async () => {
                                message.success('获取验证码成功！验证码为：1234');
                            }}
                        />
                    </div>
                )}
                <div
                    style={{
                        marginBlockEnd: 24,
                    }}
                >
                    <ProFormCheckbox noStyle name="autoLogin">
                        Auto Login
                    </ProFormCheckbox>
                    {
                        loginType === "account" ?
                            <a
                                style={{
                                    float: 'right',
                                }}
                            >
                                Forget Password
                            </a> :
                            null
                    }
                </div>
            </LoginFormPage>
        </div>
    );
};

const Login = () => {

    return (
        <ProConfigProvider dark locale={enUS}>
            <Page />
            <div style={{position: "fixed", zIndex: 9999, bottom: 30, display: "flex", justifyContent: "center", width: "100%"}}>
                <div style={{textAlign: "center", fontSize: 16, color: "#999", flexWrap: "wrap", justifyContent: "center", gap: "0 20px"}}>
                    <div>Copyright © 2023 AIVIZ.Co</div>
                    {/*<span style={{color: "#999", textDecoration: "underline", cursor: "pointer"}} onClick={() => window.open("https://beian.miit.gov.cn/")}>京ICP备2023012057号-2</span>*/}
                    <div><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023020878号-4</a></div>
                </div>
            </div>
        </ProConfigProvider>
    )
}

export default Login