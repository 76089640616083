// DataContext.js
import React, { createContext, useState } from 'react';

const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [options, setOptions] = useState([]);
    const [players, setPlayers] = useState([]);
    const [darkMode, setDarkMode] = useState(localStorage.getItem("toolsDarkMode") !== "false");
    
    return (
        <DataContext.Provider value={{ options, setOptions, players, setPlayers, darkMode, setDarkMode }}>
            {children}
        </DataContext.Provider>
    );
};

export { DataContext, DataProvider };